<template>
  <div>
    <link
      rel="stylesheet"
      href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"
    />
    <a
      href="https://api.whatsapp.com/send?phone=526251006172"
      class="float"
      target="_blank"
    >
      <i class="fa fa-whatsapp my-float"></i>
    </a>
    <!-- home -->
    <section class="home home--circle" id="home">
      <!-- particles -->
      <div id="particles-js" class="home__particles zindex-fix"></div>
      <!-- end particles -->

      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-8 col-lg-6 col-xl-5">
            <div class="home__content">
              <h1 class="home__title">¡Invierte en criptomonedas desde hoy!</h1>
              <p class="home__text">
                La forma más fácil de adquirir criptomonedas. Comienza con
                CriptoMex, revolución financiera al alcance de tus manos.
              </p>
              <a href="#contacto" class="btn btn--shadow" data-scroll
                >Invierte ya</a
              >
            </div>
          </div>
          <div class="col-sm-6 col-md-6 mt-md-15 col-lg-6 col-xl-5 offset-xl-0">
            <img
              src="https://i.ibb.co/9vQK3B4/cryptomex-home-logo.png"
              alt=""
            />
          </div>
        </div>
      </div>

      <a href="#criptos" data-scroll class="home__btn">
        <i class="ti-angle-double-down"></i>
      </a>
    </section>
    <!-- end home -->
    <!-- criptomonedas -->
    <section class="section" id="criptos">
      <div class="container">
        <div class="row">
          <!-- section title -->
          <div
            class="
              col-12 col-sm-10
              offset-sm-1
              col-lg-8
              offset-lg-2
              col-xl-6
              offset-xl-3
            "
          >
            <h1
              class="section__title"
              style="font-weight: bold; color: #2d882d"
            >
              Mexican Crypto Exchange
            </h1>
            <h2 class="section__title">Criptomonedas</h2>
          </div>
          <!-- end section title -->
        </div>
        <!-- crypto table -->
        <table
          class="table table-hover col-md-12"
          style="margin-left: auto; margin-right: auto"
        >
          <thead class="table_row">
            <tr>
              <th class="d-none d-sm-table-cell">#</th>
              <th class="pl-8">Nombre</th>
              <th>PrecioMXN</th>
              <th class="d-none d-lg-table-cell">PrecioUSD</th>
              <th style="width: 25%" class="d-none d-sm-table-cell">Gráfico</th>
            </tr>
          </thead>
          <tbody>
            <tr class="table_row" v-for="(moneda, key) in top_10" :key="key">
              <td scope="row" class="d-none d-sm-table-cell">{{ key + 1 }}</td>
              <td>
                <img :src="moneda.url" alt="" class="crypto_logo_BS" />{{
                  moneda.nombre
                }}
                ({{ moneda.simbolo }})
              </td>
              <td>{{ moneda.precioMXN }}</td>
              <td class="d-none d-lg-table-cell">{{ moneda.precioUSD }}</td>
              <td class="d-none d-sm-table-cell">
                <v-sparkline
                  :labels="[]"
                  :value="moneda.grafica"
                  color="blue"
                  line-width="2"
                  padding="16"
                ></v-sparkline>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- end crypto table -->
        <!-- servicios -->
        <div class="row">
          <div class="col-md-5 offset-lg-1 col-sm-12">
            <div class="service" style="background-color: #78b0ef">
              <h3 class="service__title" style="text-align: center">
                10 Index
              </h3>
              <p class="service__text">
                10 índex CriptoMex se consiste con las 10 criptomonedas de mayor
                capitalización y mayor futuro en el mercado del mismo, juntado
                estas 10 criptomonedas en su portafolio. Y el índice se pondera
                para tener un impacto igual para todas las monedas.
              </p>
            </div>
          </div>
          <div class="col-md-5 col-sm-12">
            <div class="service" style="background-color: #78b0ef">
              <h3 class="service__title" style="text-align: center">
                Compra y Venta de Criptomonedas
              </h3>
              <p class="service__text">
                Compra, vende, envía y almacena tus criptomonedas como Bitcoin,
                Ethereum, Cardano, Ripple o Litecoin de forma segura con
                Criptomex. Todo con la ayuda de nuestros asesores técnicos.
              </p>
            </div>
          </div>
        </div>
        <!-- end servicios -->
      </div>
    </section>
    <!-- end criptomonedas -->
    <!-- quienes somos -->
    <div
      class="section"
      id="nosotros"
      style="background-image: ; background-size: cover"
    >
      <div class="container">
        <div class="row">
          <div class="col-12">
            <!-- section title -->
            <div
              class="
                col-12 col-sm-10
                offset-sm-1
                col-md-8
                offset-md-2
                col-lg-6
                offset-lg-3
                col-xl-4
                offset-xl-4
              "
            >
              <br />
              <br />
              <h2
                class="section__title section__title--white"
                style="color: #444"
              >
                ¿Que es CriptoMex?
              </h2>
              <p class="section__text section__text--white" style="color: #444">
                Somos una empresa mexicana
              </p>
            </div>
            <!-- end section title -->
          </div>
          <!-- mission -->
          <div class="col-md-5 offset-lg-1 col-sm-12">
            <div class="feature pt-3 pb-3">
              <i class="ti-bar-chart pt-2"></i>
              <h3 class="feature__title">Misión</h3>
              <p class="feature__text pl-2 pr-2">
                Creemos que todo el mundo debe de poder tener el acceso a la
                gran digitalización del mundo proporcionándoles las herramientas
                para poder adquirirlos, ya que fácilmente tiene el potencial de
                cambiar el mundo financiero como lo vemos.
              </p>
            </div>
          </div>
          <!-- end mission -->
          <!-- vision -->
          <div class="col-md-5 col-sm-12">
            <div class="feature pt-3 pb-3">
              <i class="ti-world pt-2"></i>
              <h3 class="feature__title">Visión</h3>
              <p class="feature__text pl-2 pr-2">
                Sabemos que CriptoMex puede crear un gran cambio en
                Latinoamérica, igualando el juego entre ricos y pobres en todas
                las partes del mundo. queremos ser el puente que te ayude a
                cruzar a esta gran revolución financiera que ya comenzó.
              </p>
            </div>
          </div>
          <!-- end vision -->
        </div>
      </div>
    </div>
    <!-- end quienes somos -->
    <!-- como invertir -->
    <section class="section" id="invertir">
      <div class="container">
        <div class="row">
          <!-- section title -->
          <div
            class="
              col-12 col-sm-10
              offset-sm-1
              col-lg-8
              offset-lg-2
              col-xl-6
              offset-xl-3
            "
          >
            <h2 class="section__title">¿Como Invertir?</h2>
            <p class="section__text">
              Puedes invertir en la empresa desde 1,000 pesos mexicanos en tu
              distribuidor mas cercano o mediante contacto en la pagina oficial
              de CriptoMex. la empresa se encarga de ubicar y convertir tu
              inversión a criptomonedas en el portafolio de 10 index.
            </p>
          </div>
          <!-- end section title -->
        </div>
        <div class="row row--grid">
          <div class="col-12 col-sm-6 col-xl-4">
            <!-- feature -->
            <div class="feature">
              <i class="ti-money"></i>
              <h3 class="feature__title">Invierte desde 1,000 MXN</h3>
              <p class="feature__text">
                Al momento de realizar tu pago este se vera reflejado en tu
                usuario.
              </p>
            </div>
            <!-- end feature -->
          </div>

          <div class="col-12 col-sm-6 col-xl-4">
            <!-- feature -->
            <div class="feature">
              <i class="ti-wallet"></i>
              <h3 class="feature__title">Control total de tu inversión</h3>
              <p class="feature__text">
                Podrás solicitar retiros y transferencias a billeteras
                virtuales.
              </p>
            </div>
            <!-- end feature -->
          </div>

          <div class="col-12 col-sm-6 col-xl-4">
            <!-- feature -->
            <div class="feature">
              <i class="ti-stats-up"></i>
              <h3 class="feature__title">Estadísticas en tiempo real</h3>
              <p class="feature__text">
                Puedes monitorear a cualquier hora los valores de tu inversión.
              </p>
            </div>
            <!-- end feature -->
          </div>

          <div class="col-12 col-sm-6 col-xl-4">
            <!-- feature -->
            <div class="feature">
              <i class="ti-thumb-up"></i>
              <h3 class="feature__title">Distribuye tus inversiones</h3>
              <p class="feature__text">
                Nuestros expertos te ayudaran a elegir las mejores inversiones
                para tu economía.
              </p>
            </div>
            <!-- end feature -->
          </div>
        </div>
      </div>
    </section>
    <!-- end como invertir -->

    <!-- formulario -->
    <section class="section" id="contacto">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div
              class="
                col-12 col-sm-10
                offset-sm-1
                col-lg-8
                offset-lg-2
                col-xl-6
                offset-xl-3
              "
            >
              <h3 class="section__title">Comienza a invertir desde hoy!</h3>
              <p class="section__text">
                Escribe tu información y el tipo de operacíon que quisieras
                realizar (10 Index o la compra de alguna moneda en específico) y
                nuestros asesores se pondrán en contacto contigo para que
                comiences a generar beneficios!
              </p>
              <div class="col-12">
                <form
                  action=""
                  method="POST"
                  class="form form--contacts form--contacts-page"
                  id="my-form"
                  @submit.prevent="createNotification"
                >
                  <input
                    type="text"
                    name="Nombre"
                    class="form__input"
                    placeholder="Nombre"
                    v-model="name"
                  />
                  <small
                    >Por favor llena al menos un dato para poder
                    contactarte!</small
                  >

                  <input
                    type="email"
                    name="Correo Electronico"
                    class="form__input"
                    placeholder="Correo Electrónico"
                    v-model="email"
                  />

                  <input
                    type="text"
                    name="Telefono"
                    class="form__input"
                    placeholder="Telfono"
                    v-model="phone_number"
                  />

                  <textarea
                    class="form__textarea"
                    placeholder="Descripción"
                    name="Descripcion"
                    v-model="message"
                  ></textarea>

                  <button class="btn" type="submit" value="send">Enviar</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- end formulario -->
    <!-- sabias que -->
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="review">
              <h1>Sabias que...</h1>
              <blockquote class="review__blockquote">
                Allá por el año 2011, el Bitcoin valía unos 30 centavos por
                unidad. Por lo tanto, con una inversión de tan solamente 100
                dólares hubieras conseguido una cartera de algo más de 300
                Bitcoins. esta moneda acaba de romper un nuevo récord al superar
                un valor de 63,520 dólares... ¡por cada criptomoneda! <br />
                Esta inversión hubiera dado como resultado una ganancia de más
                de 19 millones de dólares!
              </blockquote>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="review">
              <blockquote class="review__blockquote">
                “Mientras que los extraños pueden ver el valor de la industria
                de la criptomoneda de mas de mil millones de dólares como un
                hito increíblemente significativo, en realidad está todavía en
                una de sus primeras etapas de desarrollo y crecimiento.”
              </blockquote>
              <span class="review__autor">
                Sergey Nazarov
                <span>CEO y Cofundador de Chainlink</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- end sabias que -->
    <v-snackbar v-model="snackbar">
      {{ responseText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import particle from "@/assets/js/particles-app.js";
import axios from "axios";
export default {
  name: "Home",
  data() {
    return {
      top_10: [],
      name: "",
      email: "",
      phone_number: "",
      message: "",
      responseText: "",
      snackbar: false,
    };
  },
  methods: {
    createNotification() {
      let config = {
        url: process.env.VUE_APP_APIURL + "createGuestNotification/",
        method: "post",
        data: {
          message: this.message,
          name: this.name,
          phone_number: this.phone_number,
          email: this.email,
        },
        headers: {
          "X-CSRFToken": this.$cookies.get("csrftoken"),
        },
      };

      axios(config)
        .then((response) => {
          this.responseText = "Mensaje enviado";
          this.snackbar = true;
          this.name = "";
          this.email = "";
          this.phone_number = "";
          this.message = "";
        })
        .catch((err) => {
          console.log(err);
          this.responseText = "Error al enviar mensaje";
          this.snackbar = true;
        });
    },
    getCoinData(idData) {
      let config = {
        url:
          "https://api.coingecko.com/api/v3/coins/" +
          idData +
          "?market_data=true&sparkline=true",
        method: "get",
      };
      axios(config).then((response) => {
        console.log(response.data);
        let coin = {
          nombre: response.data.name,
          simbolo: response.data.symbol.toUpperCase(),
          precioMXN: response.data.market_data.current_price.mxn,
          precioUSD: response.data.market_data.current_price.usd,
          url: response.data.image.small,
          grafica: response.data.market_data.sparkline_7d,
        };
        this.top_10.push(coin);
      });
    },
    getListCoinsData(idsData) {
      let config = {
        params: {
          vs_currency: "usd",
          ids: idsData.join(),
          sparkline: true,
        },
        url: "https://api.coingecko.com/api/v3/coins/markets",
        method: "get",
      };
      let config2 = {
        params: {
          vs_currency: "mxn",
          ids: idsData.join(),
          sparkline: true,
        },
        url: "https://api.coingecko.com/api/v3/coins/markets",
        method: "get",
      };
      axios.all([axios(config), axios(config2)]).then(
        axios.spread((response, response2) => {
          //console.log(response.data, response2.data);
          for (let i = 0; i < response.data.length; i++) {
            let formatedCoin = {
              nombre: response.data[i].name,
              simbolo: response.data[i].symbol.toUpperCase(),
              precioMXN: this.formatCoins(
                false,
                response2.data[i].current_price
              ),
              precioUSD: this.formatCoins(true, response.data[i].current_price),
              url: response.data[i].image,
              grafica: response.data[i].sparkline_in_7d.price,
            };
            //console.log(formatedCoin);
            this.top_10.push(formatedCoin);
          }
        })
      );
    },
    fetchCriptos() {
      let config = {
        url: process.env.VUE_APP_APIURL + "fetchCryptos/",
        method: "get",
      };
      axios(config)
        .then((response) => {
          let idsArr = [];
          for (let index = 0; index < response.data.length; index++) {
            const element = response.data[index].apiCoinId;
            idsArr.push(element);
          }
          console.log(idsArr);

          this.getListCoinsData(idsArr);
        })
        .catch((err) => {
          this.logSnackbar(err.response.data);
          console.log(err);
        });
    },
    formatCoins(usd, qty) {
      var formatterUsd = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

      var formatterMxn = new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

      if (usd) {
        return formatterUsd.format(qty);
      }
      return formatterMxn.format(qty);
    },
  },
  mounted() {
    // this.getCoinData("bitcoin")
    this.fetchCriptos();

    particle.particlejs();
  },
};
</script>

<style>
.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366 !important;
  color: #fff !important;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}
.float:hover {
  text-decoration: none;
  color: #25d366 !important;
  background-color: #fff !important;
}

.my-float {
  margin-top: 16px;
}
.table_row {
  height: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.crypto_logo_BS {
  max-height: 50px;
  margin: 20px;
}

.zindex-fix {
  z-index: 0 !important;
}
</style>
