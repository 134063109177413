<template>
  <div class="cho-container p-3"></div>
</template>

<script>
import axios from "axios";

export default {
  name: "MercadoPago",
  props: {
    clientOrderId: {
      type: String
    }
  },
  methods: {
    /**
     * retrieve a checkout session and redirect to stripe checkout page
     */
    doMercadoPagoCheckout() {
      // Agrega credenciales de SDK
      const mp = new MercadoPago(process.env.VUE_APP_MERCADO, {
        locale: "es-MX",
      });

      let config = {
        url: process.env.VUE_APP_APIURL + "doMercadoPagoCheckout",
        method: "get",
        params: {
          client_order_id: this.clientOrderId
        }
      };

      axios(config)
          .then((response) => {
            // Inicializa el checkout
            mp.checkout({
              preference: {
                id: response.data.id
              },
              render: {
                container: ".cho-container", // Indica el nombre de la clase donde se mostrará el botón de pago
                label: "Págo con tarjeta", // Cambia el texto del botón de pago (opcional)
              },
            });
          })
          .catch((err) => {
            console.log(err)
          });
    },
  },
  mounted() {
    this.doMercadoPagoCheckout()
  }
}
</script>

<style scoped>

</style>
