import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify'
import Vuex from 'vuex'
import store from './store'
import VueCookies from 'vue-cookies'

Vue.config.productionTip = false

Vue.use(Vuex)
Vue.use(VueCookies)

// set default config
Vue.$cookies.config('7d')
// set global cookie
Vue.$cookies.set('theme','default');
Vue.$cookies.set('hover-time','1s');

new Vue({
  router,
  vuetify,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')

