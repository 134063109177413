<template>
    <!-- 404 -->
	<div class="sign">
		<!-- particles -->
		<div id="particles-js" class="sign__particles"></div>
		<!-- end particles -->

		<div class="sign__content">
			<div class="sign__error">
				<h1>404</h1>
				<p>The page you are looking for not available!</p>
				<a href="index.html" class="btn">Go back</a>
			</div>
		</div>
	</div>
	<!-- end 404 -->
</template>

<script>
export default {
    name: "NotFound"
}
</script>