<template>
  <!-- sign form -->
  <div class="sign">
    <!-- particles -->
    <div id="particles-js" class="home__particles zindex-fix"></div>
    <!-- end particles -->

    <div class="sign__content">
      <!-- form -->
      <form action="#" class="sign__form" v-on:submit.prevent="onSubmit">
        <a href="/"
        ><img
            class="sign__logo"
            src="../assets/img/CriptoMex-removebg.png"
            alt=""
            style="height: 100px"
        /></a>

        <input
            type="text"
            class="form__input"
            placeholder="Usuario"
            v-model="username"
            disabled
        />

        <input
            type="text"
            class="form__input"
            placeholder="Número de orden"
            v-model="order_id"
            disabled
        />
        <h4>${{this.$store.state.checkout.order_total}} MXN</h4>
        <!--<stripe-checkout :order-number="Number(order_id)" :client-username="username" class="mb-3"></stripe-checkout>-->
        <mercado-pago :client-order-id="order_id"></mercado-pago>
        <PayPal
            :amount="String(this.$store.state.checkout.order_total)"
            currency="MXN"
            :client="paypal"
            env="production"
            :button-style="paypalStyle"
            v-on:payment-authorized="paymentAuthorized"
            v-on:payment-completed="paymentCompleted"
            v-on:payment-cancelled="paymentACancelled"

        >
        </PayPal>
        <br/>
        <div
            id="panel"
            class="pl-2 pr-2 pt-2 d-none"
            style="background-color: #78b0ef; color: #ffffff; border-radius: 10px"
        >
          <p>
            Contacta a nuestros asesores técnicos al correo
            criptomexchange@gmail.com, ellos se pondrán en contacto contigo.
          </p>
        </div>
      </form>
    </div>
    <v-snackbar v-model="snackbar">
      {{ responseText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
  <!-- end sign form -->
</template>

<script>
import particle from "@/assets/js/particles-app.js";
import axios from "axios";
import StripeCheckout from "../components/StripeCheckout";
import PayPal from 'vue-paypal-checkout'
import MercadoPago from "../components/MercadoPago";


export default {
  name: "Payment",
  components: {
    StripeCheckout,
    PayPal,
    MercadoPago
  },
  data() {
    return {
      username: this.$route.query.username || "Customer0234",
      order_id: this.$route.query.order_id || this.$store.state.checkout.order_id,
      responseText: "",
      snackbar: false,
      paypal: {
        sandbox: process.env.VUE_APP_SANDBOX_CLIENT_ID,
        production: process.env.VUE_APP_PRODUCTION_CLIENT_ID
      },
      paypalStyle: {
        label: 'checkout',
        size: 'large',
        shape: 'pill',
        color: 'gold'
      }

    };
  },
  methods: {
    logSnackbar(msg) {
      this.responseText = msg.message;
      this.snackbar = true;
    },
    onSubmit() {
      return
    },
    getCheckoutTotal() {
      this.$store
          .dispatch("checkout/getCheckoutTotal", {
            client_order_id: this.order_id
          })
    },
    paymentAuthorized() {
      console.log('Authorized')
    },
    paymentCompleted() {
      this.logSnackbar({message: "El pago ha sido completado, gracias por su preferencia!"})
      this.$store.commit('checkout/setOrderId', {order_id:this.order_id})
      this.$store.commit('checkout/setPayedWith', {payed_with:'Paypal'})
      this.$store.commit('checkout/setSessionId', {session_id: 'No existe stripe ID (Paypal)'})
      this.$router.push({name:"Thanks"})
      console.log('Completed')
    },
    paymentACancelled() {
      this.logSnackbar({message: "El pago ha sido declinado o cancelado por paypal. No se le ha cobrado."})
      console.log('Cancelled')
    }
  },
  mounted() {
    particle.particlejs();
    if (!this.$route.query.username || !this.$route.query.order_id) {
      if (!this.$store.state.checkout.order_id) {
        this.logSnackbar({message: "Hubo un error al cargar los datos"})
        return
      }
      return
    }
    this.getCheckoutTotal()
  },
};
</script>

<style scoped>
.zindex-fix {
  z-index: 0 !important;
}
</style>