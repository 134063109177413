<template>
  <!-- sign form -->
  <div class="sign">
    <!-- particles -->
    <div id="particles-js" class="home__particles zindex-fix"></div>
    <!-- end particles -->

    <div class="sign__content">
      <!-- form -->
      <form action="#" class="sign__form" v-on:submit.prevent="onSubmit">
        <a href="/"
          ><img
            class="sign__logo"
            src="../assets/img/CriptoMex-removebg.png"
            alt=""
            style="height: 100px"
        /></a>

        <input
          type="text"
          class="form__input"
          placeholder="Usuario"
          v-model="username"
        />

        <input
          type="password"
          class="form__input"
          placeholder="Contraseña"
          v-model="password"
        />

        <button class="btn" type="submit">Iniciar sesión</button>

        <div class="sign__forgot" v-on:click="myFunction()">
          Recuperar contraseña
        </div>
        <br />
        <div
          id="panel"
          class="pl-2 pr-2 pt-2 d-none"
          style="background-color: #78b0ef; color: #ffffff; border-radius: 10px"
        >
          <p>
            Contacta a nuestros asesores técnicos al correo
            criptomexchange@gmail.com, ellos se pondrán en contacto contigo.
          </p>
        </div>
      </form>
      <!-- end form -->
      <!-- no tienes cuenta 
      <div class="sign__box">
        <p>
          Aún no tienes cuenta?
          <router-link to="register"><div>Registrate</div></router-link>
        </p>
      </div>

       no tienes cuenta end -->
    </div>
          <v-snackbar v-model="snackbar">
        {{ responseText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
  </div>
  <!-- end sign form -->
</template>

<script>
import particle from "@/assets/js/particles-app.js";
import axios from "axios";

export default {
  name: "Login",
  data() {
    return {
      username: null,
      password: null,
      responseText: "",
      snackbar: false,
    };
  },
  methods: {
    myFunction() {
      document.getElementById("panel").classList.remove("d-none");
    },
    getCSRFToken() {
      //Todo: Missing function in backend
      return axios
        .get(process.env.VUE_APP_APIURL + "get-csrf-token/")
        .then((response) => {
          this.$cookies.set("csrftoken", response.data.token);
        })
        .catch((error) => console.log(error));
    },
    checkIfIsAdmin() {
      let csrftoken = this.$cookies.get("csrftoken");

      //console.log(this.$store.state.auth.accessToken);

      let config = {
        url: process.env.VUE_APP_APIURL + "isAdminUser/",
        method: "get",
        withCredentials: true,
        headers: {
          "X-CSRFToken": csrftoken,
          Authorization: "Bearer " + this.$store.state.auth.accessToken,
        },
      };
      return axios(config)
        .then((response) => {
          this.$store.dispatch("auth/setIsAdmin", response.data.isAdmin);
        })
        .catch((error) => console.log(error));
    },
    logSnackbar(msg) {
      this.responseText = msg.message;
      this.snackbar = true;
    },
    onSubmit() {
      this.$store
        .dispatch("auth/loginUser", {
          username: this.username,
          password: this.password,
        })
        .then(() => {
          //console.log("logged");
          const dataObj = {
            cookies: this.$cookies.get("csrftoken"),
            token: this.$store.state.auth.accessToken,
          };

          Promise.all([this.checkIfIsAdmin(), this.getCSRFToken()]).then(
            (values) => {
              if (!this.$store.state.auth.isAdmin) {
                this.$router.push({ name: "Dashboard" });
              } else {
                this.$router.push({ name: "DashboardAdmin" });
              }
            }
          );
        })
        .catch((err) => {
          this.logSnackbar({ message: "Credenciales incorrectas (usuario o contraseña)"});
          console.log(err);
          //todo: missing alerts
        });
    },
  },
  mounted() {
    particle.particlejs();
  },
};
</script>

<style scoped>
.zindex-fix {
  z-index: 0 !important;
}
</style>