import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import createPersistedState from "vuex-persistedstate"
import checkout from "./modules/checkout";

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        // módulo para login & logout
        auth: auth,
        checkout: checkout
    },
    plugins: [createPersistedState()]

})

export default store