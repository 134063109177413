import axios from 'axios'


const state = () => ({
    order_id: null,
    session_id: null,
    payed_with: "Efectivo",
    order_total: null
})

// getters
const getters = {
    getOrderId(state) {
        return state.checkout.order_id
    },
    getSessionId(state) {
        return state.checkout.session_id
    },
    getPayedWith(state) {
        return state.checkout.payed_with
    },
    getOrderTotal(state) {
        return state.checkout.order_total
    },
}

// actions
const actions = {
    doStripeCheckout(context, data) {
        let stripe = data.stripe
        let config = {
            url: process.env.VUE_APP_APIURL + "doStripeCheckout/",
            method: "get",
            params: {
                client_username: data.client_username,
                client_order_id: data.client_order_id,
            },
            withCredentials: false,
            headers: {},
        };
        return new Promise((resolve, reject) => {
            axios(config)
                .then((response) => {
                    console.log(response.data)
                    context.commit('setCheckout', {
                        session_id: response.data.session_id,
                        order_id: response.data.order_id,
                        payed_with: response.data.payed_with
                    })
                    stripe.redirectToCheckout({sessionId: response.data.session_id})
                    resolve()
                })
                .catch((err) => {
                    context.commit('restoreCheckout')
                    reject(err)
                });

        })
    },
    getCheckoutTotal(context, data) {
        let config = {
            url: process.env.VUE_APP_APIURL + "getCheckoutTotal/",
            method: "get",
            params: {
                client_order_id: data.client_order_id
            },
            withCredentials: false,
            headers: {},
        };
        return new Promise((resolve, reject) => {
            axios(config)
                .then((response) => {
                    console.log(response.data)
                    context.commit('setTotal', {
                        order_total: response.data.order_total
                    })
                    context.commit('setOrderId', {
                        order_total: response.data.order_id
                    })
                    resolve()
                })
                .catch((err) => {
                    context.commit('restoreCheckout')
                    reject(err)
                });

        })
    },
    restoreCheckout(context) {
        context.commit('restoreCheckout')

    }
}

// mutations
const mutations = {
    setTotal(state, {order_total}) {
        state.order_total = order_total
    },
    setOrderId(state, {order_id}) {
        state.order_id = order_id
    },
    setPayedWith(state, {payed_with}) {
        state.payed_with = payed_with
    },
    setSessionId(state, {session_id}) {
        state.session_id = session_id
    },
    setCheckout(state, {order_id, session_id, payed_with}) {
        state.order_id = order_id
        state.session_id = session_id
        state.payed_with = payed_with
    },
    restoreCheckout(state) {
        state.order_id = null
        state.session_id = null
        state.payed_with = null
        state.order_total = null
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}