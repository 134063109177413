<template>
  <div class="sign">
    <!-- particles -->
    <div id="particles-js" class="home__particles zindex-fix"></div>
    <!-- end particles -->

    <div class="sign__content">
      <!-- form -->
      <form action="#" class="sign__form" v-on:submit.prevent="goPayment">
        <a href="/"
        ><img
            class="sign__logo"
            src="../assets/img/CriptoMex-removebg.png"
            alt=""
            style="height: 100px"
        /></a>

        <p>Tu pago ha sido recibio. Criptomex agradece su preferencia.</p>

        <br/>
        <div
            id="panel"
            class="pl-2 pr-2 pt-2 d-none"
            style="background-color: #78b0ef; color: #ffffff; border-radius: 10px"
        >
          <p>
            Contacta a nuestros asesores técnicos al correo
            criptomexchange@gmail.com, ellos se pondrán en contacto contigo.
          </p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Thanks",
  methods: {
    updateCheckoutPayed() {
      let config = {
        url: process.env.VUE_APP_APIURL + "updateCheckoutPayed/",
        method: "post",
        data: {
          order_id: this.$store.state.checkout.order_id,
          session_id: this.$store.state.checkout.session_id,
          payed_with: this.$store.state.checkout.payed_with
        },
        withCredentials: false,
        headers: {
          "X-CSRFToken": this.$cookies.get("csrftoken")
        },
      };
      axios(config)
          .then(() => {
            this.$store
                .dispatch("checkout/restoreCheckout").then(()=>{
              console.log('Checkout restored')
            })
          })
          .catch((err) => {
            console.log(err)
          });

    }
  },
  mounted() {
    const delay = ms => new Promise(res => setTimeout(res, ms));
    delay(1000)
    this.updateCheckoutPayed() // outputs 'yay'
  }
}
</script>

<style scoped>

</style>