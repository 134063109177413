<template>
  <v-container style="margin-top: 90px">
    <v-row>
      <v-col md="12">
        <button
          style="
            height: 48px;
            padding-left: 16px;
            padding-right: 16px;
            border-radius: 32px;
            background-color: #78b0ef;
            color: white;
            margin-bottom: 16px;
          "
          @click="showCoingecko = true; getListCoinsData()"
        >
          Ver criptos en coingecko.com
        </button>
        <v-data-table
          v-if="showCoingecko"
          :headers="coinGeckoHeader"
          :items="coinsGecko"
          sort-by="name1"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title> Criptos (coingecko.com) </v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
            </v-toolbar>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="4">
        <v-card class="bs_card">
          <h3>Agregar Nueva Cripto</h3>
          <v-text-field
            v-model="apiId"
            label="Api Id"
            required
            class="bs_input"
          ></v-text-field>
          <v-text-field
            v-model="name"
            label="Nombre"
            required
            class="bs_input"
          ></v-text-field>
          <v-text-field
            v-model="symbol"
            label="Simbolo"
            required
            class="bs_input"
          ></v-text-field>
          <v-text-field
            v-model="priceMxn"
            label="Precio MXN"
            required
            class="bs_input"
          ></v-text-field>
          <v-text-field
            v-model="priceUsd"
            label="Precio USD"
            required
            class="bs_input"
          ></v-text-field>
          <v-text-field
            v-model="price3short"
            label="Precio 3 Short"
            required
            class="bs_input"
          ></v-text-field>
          <v-text-field
            v-model="price3long"
            label="Precio 3 Long"
            required
            class="bs_input"
          ></v-text-field>
          <v-text-field
            v-model="imageUrl"
            label="Imagen"
            required
            class="bs_input"
          ></v-text-field>
          <v-color-picker
            v-model="color"
            class="ma-2"
            hide-canvas
            hide-inputs
          ></v-color-picker>
          <v-btn depressed @click="addCrypto" v-if="!edittingCoin">
            Crear
          </v-btn>
          <v-btn
            depressed
            @click="updateCrypto"
            v-if="edittingCoin"
            style="margin-left: 8px"
          >
            Editar
          </v-btn>
        </v-card>
      </v-col>
      <v-col md="8">
        <v-data-table
          :headers="headersCheckout"
          :items="coins"
          sort-by="name1"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title> Criptos </v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small @click="editCoin(item)"> mdi-pencil </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar">
      {{ responseText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
import axios from "axios";
export default {
  name: "Dashboard",
  components: {},
  data() {
    return {
      showCoingecko: false,
      apiId: "",
      editingCoinId: null,
      name: "",
      symbol: "",
      priceMxn: "",
      priceUsd: "",
      price3long: "",
      price3short: "",
      imageUrl: "",
      color: "",
      responseText: "",
      snackbar: false,
      edittingCoin: false,
      coins: [],
      headersCheckout: [
        {
          text: "Id",
          align: "start",
          filterable: true,
          sortable: true,
          value: "id",
        },
        {
          text: "ApiId",
          filterable: true,
          sortable: true,
          value: "apiCoinId",
        },
        {
          text: "Nombre",
          filterable: true,
          sortable: true,
          value: "name",
        },
        { text: "Simbolo", value: "symbol" },
        { text: "PrecioUsd", value: "priceUsdFormatted" },
        { text: "PrecioMxn", value: "priceMxnFormatted" },
        { text: "Precio 3 short", value: "price3short" },
        { text: "Precio 3 long", value: "price3long" },
        { text: "Acciones", value: "actions" },
      ],
      coinsGecko: [],
      coinGeckoHeader: [
        {
          text: "Api Id",
          filterable: true,
          sortable: true,
          value: "apiId",
        },
        {
          text: "Nombre",
          filterable: true,
          sortable: true,
          value: "nombre",
        },
        { text: "Simbolo", value: "simbolo" },
        { text: "PrecioUsd", value: "precioMXN" },
        { text: "PrecioMxn", value: "precioUSD" },
        { text: "Imagen", value: "url" },
      ],
    };
  },
  methods: {
    getListCoinsData() {
      let config = {
        params: {
          vs_currency: "usd",
        },
        url: "https://api.coingecko.com/api/v3/coins/markets",
        method: "get",
      };
      let config2 = {
        params: {
          vs_currency: "mxn",
        },
        url: "https://api.coingecko.com/api/v3/coins/markets",
        method: "get",
      };
      axios.all([axios(config), axios(config2)]).then(
        axios.spread((response, response2) => {
          //console.log(response.data, response2.data);
          for (let i = 0; i < response.data.length; i++) {
            let formatedCoin = {
              apiId: response.data[i].id,
              nombre: response.data[i].name,
              simbolo: response.data[i].symbol.toUpperCase(),
              precioMXN: this.formatCoins(
                false,
                response2.data[i].current_price
              ),
              precioUSD: this.formatCoins(true, response.data[i].current_price),
              url: response.data[i].image,
            };
            //console.log(formatedCoin);
            this.coinsGecko.push(formatedCoin);
          }
        })
      );
    },
    formatCoins(usd, qty) {
      var formatterUsd = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

      var formatterMxn = new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

      if (usd) {
        return formatterUsd.format(qty);
      }
      return formatterMxn.format(qty);
    },
    fetchCriptos() {
      let config = {
        url: process.env.VUE_APP_APIURL + "fetchCryptos/",
        method: "get",
      };
      axios(config)
        .then((response) => {
          var formatterUsd = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",

            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
          });

          var formatterMxn = new Intl.NumberFormat("es-MX", {
            style: "currency",
            currency: "MXN",

            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
          });
          for (let index = 0; index < response.data.length; index++) {
            response.data[index]["priceMxnFormatted"] = `${formatterMxn.format(
              response.data[index].priceMxn
            )}`;
            response.data[index]["priceUsdFormatted"] = `${formatterUsd.format(
              response.data[index].priceUsd
            )}`;
          }
          this.coins = response.data;
        })
        .catch((err) => {
          this.logSnackbar(err.response.data);
          console.log(err);
        });
    },
    addCrypto() {
      let config = {
        url: process.env.VUE_APP_APIURL + "addCrypto/",
        method: "post",
        data: {
          apiCoinId: this.apiId,
          color: this.color.slice(0, -2),
          imageUrl: this.imageUrl,
          name: this.name,
          priceMxn: this.priceMxn,
          priceUsd: this.priceUsd,
          price3short: this.price3short,
          price3long: this.price3long,
          symbol: this.symbol,
        },
      };
      axios(config)
        .then((response) => {
          //console.log(response.data);
          this.fetchCriptos();
          this.logSnackbar({ message: "Crypto creada" });
        })
        .catch((err) => {
          this.logSnackbar(err.response.data);
          console.log(err);
        });
    },
    updateCrypto() {
      let config = {
        url: process.env.VUE_APP_APIURL + "updateCrypto/" + this.editingCoinId,
        method: "put",
        data: {
          apiCoinId: this.apiId,
          color: this.color,
          imageUrl: this.imageUrl,
          name: this.name,
          priceMxn: this.priceMxn,
          priceUsd: this.priceUsd,
          price3short: this.price3short,
          price3long: this.price3long,
          symbol: this.symbol,
        },
      };
      axios(config)
        .then((response) => {
          this.logSnackbar({ message: "Crypto actualizada" });
        })
        .catch((err) => {
          this.logSnackbar(err.response.data);
          console.log(err);
        });
    },
    editCoin(item) {
      this.editingCoinId = item.id;
      this.apiId = item.apiCoinId;
      this.color = item.color;
      this.imageUrl = item.imageUrl;
      this.name = item.name;
      this.priceMxn = item.priceMxn;
      this.priceUsd = item.priceUsd;
      this.price3short = item.price3short;
      this.price3long = item.price3long;
      this.symbol = item.symbol;
      this.edittingCoin = true;
    },
    configNewCoin() {
      this.newCoin = true;
    },
    isLogged() {
      return this.$store.state.auth.isValid;
    },
    logSnackbar(msg) {
      this.responseText = msg.message;
      this.snackbar = true;
    },
  },
  mounted() {
    this.fetchCriptos();
    //this.getListCoinsData();
  },
};
</script>

<style scoped>
.bs_input {
  margin: 20px;
}

.bs_card {
  padding: 40px;
}
</style>