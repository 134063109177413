<template>
    	<!-- sign form -->
	<div class="sign">
		<!-- particles -->
		<div id="particles-js" class="sign__particles"></div>
		<!-- end particles -->

		<div class="sign__content">
			<!-- form -->
			<form action="#" class="sign__form">
				<a href="index.html"><img class="sign__logo" src="img/logo.svg" alt=""></a>

				<input type="text" class="form__input" placeholder="Name">

				<input type="text" class="form__input" placeholder="Email">

				<input type="password" class="form__input" placeholder="Password">

				<button class="btn" type="button">Sign up</button>

				<div class="form__group">
					<input id="terms" name="terms" type="checkbox">
					<label for="terms">I agree to the <a href="privacy.html">Privacy Policy</a></label>
				</div>
			</form>
			<!-- end form -->

			<div class="sign__box">
				<p>Already have an account? <a href="signin.html">Sign in</a></p>
			</div>
		</div>
	</div>
	<!-- end sign form -->
</template>

<script>
import particle from '@/assets/js/particles-app.js'

export default {
  name: 'Register',
  data() {
    return {
    }
  },
   mounted() {
	   particle.particlejs()
  },
}
</script>