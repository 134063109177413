<template>
  <div class="sign">
    <!-- particles -->
    <div id="particles-js" class="home__particles zindex-fix"></div>
    <!-- end particles -->

    <div class="sign__content">
      <!-- form -->
      <form action="#" class="sign__form" v-on:submit.prevent="goPayment">
        <a href="/"
        ><img
            class="sign__logo"
            src="../assets/img/CriptoMex-removebg.png"
            alt=""
            style="height: 100px"
        /></a>

        <p>Tu pago ha sido cancelado o rechazado por tu banco! Puedes intenrarlo de nuevo haciendo click en el boton de abajo.</p>


        <button class="btn" type="submit">Ir a págo</button>

        <br/>
        <div
            id="panel"
            class="pl-2 pr-2 pt-2 d-none"
            style="background-color: #78b0ef; color: #ffffff; border-radius: 10px"
        >
          <p>
            Contacta a nuestros asesores técnicos al correo
            criptomexchange@gmail.com, ellos se pondrán en contacto contigo.
          </p>
        </div>
      </form>
    </div>
  </div>

</template>

<script>
export default {
  name: "Rejected",
  methods: {
    goPayment() {
      this.$router.push({name: "Payment"});
    }
  }
}
</script>

<style scoped>

</style>