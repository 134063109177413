<template>
  <v-container fluid style="margin-top: 90px">
    <v-row>
      <v-col cols="12">
        <v-sheet
          elevation="1"
          height="175"
          width="100%"
          class="p-4"
          :style="{
            backgroundImage: `url(${require('@/assets/img/baner-bg.png')})`,
            color: 'white',
          }"
        >
          <h2>Ingresaste como administrador</h2>
        </v-sheet>
      </v-col>
    </v-row>
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="12">
          <v-data-table
            :headers="headersNotifications"
            :items="itemsNotifications"
            sort-by="name1"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title> Notificaciones </v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small @click="deactivateNotification(item)">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="6">
          <v-data-table
            :headers="headers"
            :items="users"
            sort-by="name1"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Usuarios</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-btn
                fab
                dark
                color="green"
                class="ml-3"
                small
                @click="newUserShow = true"
              >
                <v-icon dark> mdi-plus </v-icon>
              </v-btn>
            </template>
            <template v-slot:item.edit="{ item }">
              <v-icon small @click="getUserPorfolio(item)" class="mr-3">
                mdi-wallet
              </v-icon>
              <v-icon small @click="configEditUser(item)">
                mdi-pencil-outline
              </v-icon>
              <v-icon small @click="deleteUser(item)"> mdi-delete </v-icon>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <v-data-table
            :headers="headers2"
            :items="wallet"
            sort-by="name1"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>
                  Billetera
                  {{
                    selectedUser.first_name + " " + selectedUser.last_name
                  }}</v-toolbar-title
                >
                <v-divider class="mx-4" inset vertical></v-divider>
              </v-toolbar>
              <v-btn
                fab
                dark
                color="green"
                class="ml-3"
                small
                @click="configNewPortfolio"
                v-if="selectedUser.username !== ''"
              >
                <v-icon dark> mdi-plus </v-icon>
              </v-btn>
              <v-btn-toggle v-model="actions" borderless class="ml-3">
                <v-btn value="unlock">
                  <span class="hidden-sm-and-down">Desbloquear</span>

                  <v-icon right> mdi-lock-open-variant </v-icon>
                </v-btn>
                <v-btn value="lock">
                  <span class="hidden-sm-and-down">Bloquear</span>

                  <v-icon right> mdi-lock </v-icon>
                </v-btn>

                <v-btn
                  value="save"
                  :disabled="actions !== 'lock'"
                  @click="updateUserPortfolio"
                >
                  <span class="hidden-sm-and-down">Guardar</span>

                  <v-icon right> mdi-content-save-all </v-icon>
                </v-btn>
              </v-btn-toggle>
            </template>
            <template v-slot:item.image="{ item }">
              <div class="p-2">
                <v-img
                  :src="item.image"
                  :alt="item.name"
                  height="40px"
                  width="40px"
                ></v-img>
              </div>
            </template>
            <template v-slot:item.short3="{ item }">
              <v-text-field
                v-model.number="item.short3"
                name="short3"
                outlined
                type="number"
                hide-details="true"
                :disabled="actions == 'lock' || actions == 'save'"
              ></v-text-field>
            </template>
            <template v-slot:item.quantity="{ item }">
              <v-text-field
                v-model.number="item.quantity"
                name="quantity"
                outlined
                type="number"
                hide-details="true"
                :disabled="actions == 'lock' || actions == 'save'"
              ></v-text-field>
            </template>
            <template v-slot:item.long3="{ item }">
              <v-text-field
                v-model.number="item.long3"
                name="long3"
                outlined
                type="number"
                hide-details="true"
                :disabled="actions == 'lock' || actions == 'save'"
              ></v-text-field>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <!-- Ventanas emergentes -->
      <v-row>
        <v-dialog v-model="newPortfolio" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Agregar a billetera</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="selectedUser.username"
                      outlined
                      disabled
                      hide-details="true"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      :items="newPortfolioForm.availableCryptos"
                      label="Criptos disponibles"
                      solo
                      item-text="name"
                      item-value="id"
                      v-model="newPortfolioForm.selectedCrypto"
                    ></v-select>
                  </v-col>
                  <v-row cols="12">
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="newPortfolioForm.short3"
                        outlined
                        type="number"
                        label="3S"
                        hide-details="true"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="newPortfolioForm.quantity"
                        outlined
                        type="number"
                        hide-details="true"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="newPortfolioForm.long3"
                        outlined
                        label="3L"
                        type="number"
                        hide-details="true"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="newPortfolio = false">
                Cerrar
              </v-btn>
              <v-btn color="blue darken-1" text @click="addPortfolio">
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row>
        <v-dialog v-model="newUserShow" max-width="600px">
          <v-card>
            <v-card-title>
              <span v-if="!newUser.is_edit" class="text-h5">Crear usuario</span>
              <span else class="text-h5">Editar usuario</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="newUser.username"
                      outlined
                      hide-details="true"
                      placeholder="Usuario"
                      :disabled="newUser.is_edit"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="newUser.first_name"
                      outlined
                      hide-details="true"
                      type="text"
                      placeholder="Nombre"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="newUser.last_name"
                      outlined
                      hide-details="true"
                      type="text"
                      placeholder="Apellido"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="newUser.email"
                      outlined
                      placeholder="Correo electronico"
                      type="email"
                      hide-details="true"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-checkbox
                      v-if="newUser.is_edit"
                      v-model="newUser.changePassword"
                      label="Habilitar"
                      color="indigo"
                      hide-details
                    ></v-checkbox>
                    <p class="text-caption" v-if="newUser.is_edit">
                      Al editar un usuario y habilitar la contraseña, si se deja
                      en blanco podria perderse.
                    </p>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="newUser.password1"
                      outlined
                      placeholder="Contraseña"
                      hide-details="true"
                      type="password"
                      :disabled="!newUser.changePassword && newUser.is_edit"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="newUser.password2"
                      outlined
                      placeholder="Repetir contraseña"
                      hide-details="true"
                      type="password"
                      :disabled="!newUser.changePassword && newUser.is_edit"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="newUser.phone_number"
                      outlined
                      placeholder="Número telefonico"
                      type="text"
                      hide-details="true"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="clearNewUser">
                Cerrar
              </v-btn>
              <v-btn color="blue darken-1" text @click="createOrUpdateUser">
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-snackbar v-model="snackbar">
        {{ responseText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  name1: "DashboardAdmin",
  data() {
    return {
      newUserShow: false,
      newUser: {
        changePassword: true,
        is_edit: false,
        username: null,
        first_name: null,
        last_name: null,
        password1: null,
        password2: null,
        email: null,
        phone_number: null,
      },
      newPortfolioForm: {
        availableCryptos: [],
        selectedCrypto: null,
        quantity: 0,
        short3: 0,
        long3: 0,
      },
      newPortfolio: false,
      responseText: "",
      snackbar: false,
      actions: "save",
      selectedUser: {
        first_name: "",
        last_name: "",
        username: "",
      },
      headersNotifications: [
        {
          text: "Id",
          align: "start",
          sortable: true,
          value: "id",
        },
        { text: "Nombre", filterable: true, value: "full_name" },
        { text: "Telefono", value: "phone_number", filterable: true },
        { text: "Mensaje", value: "message" },
        { text: "Correo", value: "email" },
        { text: "Estatus", value: "status", sortable: true },
        { text: "Desactivar", value: "actions", sortable: false },
      ],
      itemsNotifications: [],
      headers2: [
        {
          text: "Imagen",
          align: "start",
          filterable: false,
          sortable: false,
          value: "image",
        },
        { text: "Nombre", filterable: true, value: "name" },
        { text: "Precio MXN", value: "priceMxn" },
        { text: "Precio USD", value: "priceUsd" },
        { text: "Short 3", value: "short3" },
        { text: "Moneda", value: "quantity" },
        { text: "Long 3", value: "long3" },
      ],
      wallet: [],
      headers: [
        {
          text: "Nombre Usuario",
          align: "start",
          filterable: true,
          value: "username",
        },
        { text: "Nombre", value: "firstName" },
        { text: "Apellido", value: "lastName" },
        { text: "Correo", value: "email" },
        { text: "Telefono", value: "phone_number" },
        { text: "Editar", value: "edit" },
      ],
      users: [],
    };
  },
  methods: {
    logSnackbar(msg) {
      this.responseText = msg.message;
      this.snackbar = true;
    },
    clearNewUser() {
      this.newUserShow = false;
      this.newUser.is_edit = false;
      this.newUser.username = null;
      this.newUser.first_name = null;
      this.newUser.last_name = null;
      this.newUser.password1 = null;
      this.newUser.password2 = null;
      this.newUser.email = null;
      this.newUser.phone_number = null;
      this.newUser.changePassword = true;
    },
    updateUser() {
      let config = {
        url: process.env.VUE_APP_APIURL + "updateUser/",
        method: "post",
        data: {
          username: this.newUser.username,
          first_name: this.newUser.first_name,
          last_name: this.newUser.last_name,
          password: this.newUser.password1,
          email: this.newUser.email,
          phone_number: this.newUser.phone_number,
          change_password: this.newUser.changePassword,
        },
        withCredentials: true,
        headers: {
          // Esto solo se manda cuando es POST
          "X-CSRFToken": this.$cookies.get("csrftoken"),
          Authorization: "Bearer " + this.$store.state.auth.accessToken,
        },
      };

      axios(config)
        .then((response) => {
          this.logSnackbar(response.data);
          this.getUsers();
          this.clearNewUser();
          //console.log(response.data);
          return;
        })
        .catch((err) => {
          this.logSnackbar(err.response.data);
          console.log(err);
        });
    },
    createUser() {
      let config = {
        url: process.env.VUE_APP_APIURL + "createUser/",
        method: "post",
        data: {
          username: this.newUser.username,
          first_name: this.newUser.first_name,
          last_name: this.newUser.last_name,
          password: this.newUser.password1,
          email: this.newUser.email,
          phone_number: this.newUser.phone_number,
        },
        withCredentials: true,
        headers: {
          // Esto solo se manda cuando es POST
          "X-CSRFToken": this.$cookies.get("csrftoken"),
          Authorization: "Bearer " + this.$store.state.auth.accessToken,
        },
      };

      axios(config)
        .then((response) => {
          this.logSnackbar(response.data);
          this.getUsers();
          this.clearNewUser();
          this.newUserShow = false;
          //console.log(response.data);
          return;
        })
        .catch((err) => {
          this.logSnackbar(err.response.data);
          console.log(err);
        });
    },
    configEditUser(item) {
      console.log(item);
      this.newUserShow = true;
      this.newUser.username = item.username;
      this.newUser.first_name = item.firstName;
      this.newUser.last_name = item.lastName;
      this.newUser.password1 = null;
      this.newUser.password2 = null;
      this.newUser.email = item.email;
      this.newUser.phone_number = item.phone_number;
      this.newUser.is_edit = true;
    },
    createOrUpdateUser() {
      if (this.newUser.password1 !== this.newUser.password2) {
        this.logSnackbar({ message: "Las contraseñas no coinciden" });
        return;
      }
      if (!this.newUser.is_edit) {
        this.createUser();
      } else {
        this.updateUser();
      }
    },
    deactivateNotification(notification) {
      let config = {
        url: process.env.VUE_APP_APIURL + "deactivateNotification/",
        method: "post",
        data: {
          notification_id: notification.id,
        },
        withCredentials: true,
        headers: {
          // Esto solo se manda cuando es POST
          "X-CSRFToken": this.$cookies.get("csrftoken"),
          Authorization: "Bearer " + this.$store.state.auth.accessToken,
        },
      };

      axios(config)
        .then((response) => {
          this.logSnackbar(response.data);
          this.fetchNotifications();
          //console.log(response.data);
          return;
        })
        .catch((err) => {
          this.logSnackbar(err.response.data);
          console.log(err);
        });
    },
    fetchNotifications() {
      let config = {
        url: process.env.VUE_APP_APIURL + "fetchNotifications/",
        method: "get",
        params: {},
        withCredentials: true,
        headers: {
          // Esto solo se manda cuando es POST
          //"X-CSRFToken": this.$cookies.get("csrftoken"),
          Authorization: "Bearer " + this.$store.state.auth.accessToken,
        },
      };

      axios(config)
        .then((response) => {
          this.itemsNotifications = response.data;
          //console.log(response.data);
          return;
        })
        .catch((err) => {
          this.logSnackbar(err.response.data);
          console.log(err);
        });
    },
    addPortfolio() {
      let config = {
        url: process.env.VUE_APP_APIURL + "createPortfolio/",
        method: "post",
        data: {
          username: this.selectedUser.username,
          crypto_id: this.newPortfolioForm.selectedCrypto,
          quantity: this.newPortfolioForm.quantity,
          short3: this.newPortfolioForm.short3,
          long3: this.newPortfolioForm.long3,
        },
        withCredentials: true,
        headers: {
          // Esto solo se manda cuando es POST
          "X-CSRFToken": this.$cookies.get("csrftoken"),
          Authorization: "Bearer " + this.$store.state.auth.accessToken,
        },
      };

      axios(config)
        .then((response) => {
          this.logSnackbar(response.data);
          this.newPortfolio = false;
          this.getUserPorfolio(this.selectedUser);
          this.newPortfolioForm = {
            availableCryptos: [],
            selectedCrypto: null,
            quantity: 0,
          };
          return;
        })
        .catch((err) => {
          this.logSnackbar(err.response.data);
          this.newPortfolio = false;
          console.log(err);
        });
    },
    async configNewPortfolio() {
      await this.fetchAvailableCryptos()
        .then((response) => {
          //console.log(response.data);
          this.newPortfolioForm.availableCryptos = response.data;
          this.newPortfolio = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchAvailableCryptos() {
      let config = {
        url: process.env.VUE_APP_APIURL + "fetchAvailableCryptos/",
        method: "get",
        params: {
          username: this.selectedUser.username,
        },
        withCredentials: true,
        headers: {
          // Esto solo se manda cuando es POST
          // "X-CSRFToken": this.$cookies.get("csrftoken"),
          Authorization: "Bearer " + this.$store.state.auth.accessToken,
        },
      };

      return axios(config);
    },
    updateUserPortfolio() {
      if (this.selectedUser.username == "") {
        this.responseText = "No hay un usuario seleccionado";
        this.snackbar = true;
        return;
      }
      let config = {
        url: process.env.VUE_APP_APIURL + "updatePortfolio/",
        method: "put",
        data: {
          username: this.selectedUser.username,
          portfolio: this.wallet,
        },
        withCredentials: true,
        headers: {
          // Esto solo se manda cuando es POST
          "X-CSRFToken": this.$cookies.get("csrftoken"),
          Authorization: "Bearer " + this.$store.state.auth.accessToken,
        },
      };

      //console.log(this.wallet);
      axios(config)
        .then((response) => {
          this.logSnackbar(response.data);
          this.getUserPorfolio(this.selectedUser);
        })
        .catch((err) => {
          this.logSnackbar(err.response.data);
          this.getUserPorfolio(this.selectedUser);
        });
    },
    getUsers() {
      let config = {
        url: process.env.VUE_APP_APIURL + "getUsers/",
        method: "get",
        data: {},
        headers: {
          // Esto solo se manda cuando es POST
          // "X-CSRFToken": this.$cookies.get("csrftoken"),
          Authorization: "Bearer " + this.$store.state.auth.accessToken,
        },
      };

      axios(config)
        .then((response) => {
          //console.log(response.data);
          this.users = response.data.usuarios;
        })
        .catch((err) => {
          this.logSnackbar(err.response.data);
          console.log(err);
        });
    },
    deleteUser(user) {
      let config = {
        url: process.env.VUE_APP_APIURL + "deleteUser/",
        method: "delete",
        data: {
          username: user.username,
        },
        headers: {
          // Esto solo se manda cuando es POST
          "X-CSRFToken": this.$cookies.get("csrftoken"),
          Authorization: "Bearer " + this.$store.state.auth.accessToken,
        },
      };
      // debugger
      axios(config)
        .then((response) => {
          this.getUsers();
          this.logSnackbar(response.data);
        })
        .catch((err) => {
          this.logSnackbar(err.response.data);
          console.log(err);
        });
    },
    getUserPorfolio(user) {
      let config = {
        url: process.env.VUE_APP_APIURL + "fetchPorfolio/",
        method: "post",
        data: {
          username: user.username,
        },
        headers: {
          // Esto solo se manda cuando es POST
          "X-CSRFToken": this.$cookies.get("csrftoken"),
          Authorization: "Bearer " + this.$store.state.auth.accessToken,
        },
      };
      // debugger
      axios(config)
        .then((response) => {
          //console.log(response.data);
          let localWallet = [];
          let portfolio = response.data.portfolio;

          var formatterUsd = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",

            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
          });

          var formatterMxn = new Intl.NumberFormat("es-MX", {
            style: "currency",
            currency: "MXN",

            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
          });

          for (let i = 0; i < portfolio.length; i++) {
            //console.log(portfolio[i]);
            let formatedPortfolio = {
              id: portfolio[i].id,
              name:
                portfolio[i].crypto.name +
                " (" +
                portfolio[i].crypto.symbol +
                ")",
              image: portfolio[i].crypto.imageUrl,
              priceMxn: `${formatterMxn.format(portfolio[i].crypto.priceMxn)}`,
              priceUsd: `${formatterUsd.format(portfolio[i].crypto.priceUsd)}`,
              short3: portfolio[i].short3,
              quantity: portfolio[i].quantity,
              long3: portfolio[i].long3,
            };

            localWallet.push(formatedPortfolio);
          }
          this.wallet = localWallet;
          this.selectedUser = response.data.user_data;
        })
        .catch((err) => {
          this.logSnackbar(err.response.data);
          console.log(err);
        });
    },
  },
  mounted() {
    this.getUsers();
    this.fetchNotifications();
  },
};
</script>