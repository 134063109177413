import axios from 'axios'

const APIUrl = process.env.VUE_APP_AUTH_BASE_URL

const state = () => ({
    isTokenValid: false,
    accessToken: localStorage.getItem('access_token') || null, // makes sure the user is logged in even after
    isAdmin: false,
    // refreshing the page
    // refreshToken: localStorage.getItem('refresh_token') || null,
    APIData: '', // received data from the backend API is stored here.
})

// getters
const getters = {
    isTokenValid(state) {
      return state.isTokenValid
    },
    loggedIn(state) {
        return state.isTokenValid
    },
    isTokenThere(state) {
      return state.accessToken
    },
    isAdmin(state){
        return state.isAdmin;
    }
}

// actions
const actions = {
    loginUser(context, credentials) {
        console.log(APIUrl)
        let data = {
            "username": credentials.username,
            "password": credentials.password
        }
        //Todo: Missing function in backend
        let config = {
            url: APIUrl + "/api/token/",
            method: "post",
            data: data,
            withCredentials: true,
            headers: {
            }
        }
        return new Promise((resolve, reject) => {
            axios(config)
                // if successful update local storage:
                .then(response => {
                    context.commit('updateAccess', { access: response.data.token, logged: true })
                    resolve(response)
                })
                .catch(err => {
                    context.commit('updateAccess', {access: null, logged: false})
                    reject(err)
                })
        })
    },
    refreshToken({commit, getters}) {
        const axiosBase = axios.create({
            baseURL: APIUrl,
            headers: {}
        })
        if(getters.isTokenThere === null || getters.isTokenThere === undefined){
          return;
        }
        const payload = {
          token: getters.isTokenThere
        }
        return new Promise((resolve, reject) => {

            axiosBase.post('/api/token/refresh/', payload) // send the stored refresh token to the backend API
                .then(response => { // if API sends back new access and refresh token update the store
                    commit('updateAccess', {access: response.data.token, logged: true})
                    resolve(response)
                })
                .catch(err => {
                    console.log('error in refreshToken Task')
                    commit('destroyToken')
                    reject(err) // error generating new access and refresh token because refresh token has expired
                })
        })
    },
    verifyToken({commit, getters}) {
      const axiosBase = axios.create({
        baseURL: APIUrl,
        headers: {}
      })
      if(getters.isTokenThere === null || getters.isTokenThere === undefined){
        return;
      }
      const payload = {
        token: getters.isTokenThere
      }
      return new Promise((resolve, reject) => {
        axiosBase.post('/api/token/verify', payload)
          .then(response => {
            commit('updateAccess', {access: response.data.token, logged: true})
            console.log("pasa2")
            resolve(response)
          })
          .catch(err => {
            console.log("b", err)
            commit('updateAccess', {access: null, logged: false})
            reject(err)
          })
      })
    },
    logoutUser(context) {
        if (context.getters.isTokenValid) {
            return new Promise((resolve, reject) => {
                context.commit('destroyToken')
                resolve()
            })
        }
    },
    setIsAdmin({ commit }, isAdmin) {
        commit('updateIsAdmin', isAdmin);
    }
}

// mutations
const mutations = {
    updateLocalStorage(state, { access, refresh }) {
        localStorage.setItem('access_token', access)
        localStorage.setItem('refresh_token', refresh)
        state.accessToken = access
        state.refreshToken = refresh
    },
    updateAccess(state, data) {
        state.accessToken = data.access
        state.isTokenValid = data.logged
    },
    destroyToken(state) {
        state.accessToken = null
        state.isTokenValid = false
        state.isAdmin = false
    },
    updateIsAdmin(state, isAdmin) {
        state.isAdmin = isAdmin;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}