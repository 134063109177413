<template>
  <v-container fluid style="margin-top: 90px">
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="12">
          <v-data-table
              :headers="headersCheckout"
              :items="itemsCheckout"
              sort-by="name1"
              class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>
                  Ordenes
                </v-toolbar-title
                >
                <v-divider class="mx-4" inset vertical></v-divider>
              </v-toolbar>
              <v-btn
                  fab
                  dark
                  color="green"
                  class="ml-3"
                  small
                  @click="configNewCheckout"
              >
                <v-icon dark> mdi-plus</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small @click="generateLink(item)">
                mdi-link
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <!-- Ventanas emergentes -->
      <v-row>
        <v-dialog v-model="newCheckout" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Nueva Orden</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-select
                        :items="users"
                        label="Usuarios"
                        solo
                        item-text="email"
                        item-value="username"
                        v-model="newCheckoutForm.selectedUser"
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                        v-model="newCheckoutForm.total"
                        outlined
                        hide-details="true"
                        label="Total a cobrar"
                    >
                      <v-icon
                          slot="prepend"
                          color="green"
                      >
                        mdi-currency-usd
                      </v-icon>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                        :items="[{name: 'Efectivo', value: 'Efectivo'},{name: 'Tarjeta', value: 'Tarjeta'},{name: 'Paypal', value: 'Paypal'}]"
                        label="Método de pago"
                        solo
                        item-text="name"
                        item-value="value"
                        v-model="newCheckoutForm.selectedPayment"
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                        v-model="newCheckoutForm.comments"
                        label="Comentarios"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="newCheckout = false">
                Cerrar
              </v-btn>
              <v-btn color="blue darken-1" text @click="addCheckout">
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-snackbar v-model="snackbar">
        {{ responseText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </v-container>

</template>

<script>
import axios from "axios";

export default {
  name: "Order",
  data() {
    return {
      users: [],
      headersCheckout: [
        {
          text: "Id",
          align: "start",
          filterable: true,
          sortable: true,
          value: "pk",
        },
        {
          text: "Correo",
          filterable: true,
          sortable: true,
          value: "customer_email",
        },
        {
          text: "Cliente",
          filterable: true,
          sortable: true,
          value: "customer.first_name",
        },
        {text: "Total", value: "price"},
        {text: "Comentarios", value: "comments"},
        {text: "Pagado en", value: "payed_on"},
        {text: "Pagado con", value: "payed_with"},
        {text: "Link", value: "actions", sortable: false},

      ],
      itemsCheckout: [],
      username: "",
      newCheckout: false,
      newCheckoutForm: {
        selectedUser: "",
        comments: "",
        total: null,
        selectedPayment: "Efectivo"
      },
      snackbar: false,
      responseText: ""
    }
  },
  methods: {
    logSnackbar(msg) {
      this.responseText = msg.message;
      this.snackbar = true;
    },
    generateLink(item) {
      alert(`https://criptomex.com.mx/payment?username=${item.customer.username}&order_id=${item.pk}`)
    },
    configNewCheckout() {
      this.newCheckout = true
    },
    getAllUsers() {
      let config = {
        url: process.env.VUE_APP_APIURL + "getUsers/",
        method: "get",
        headers: {
          Authorization: "Bearer " + this.$store.state.auth.accessToken,
        },
      };

      axios(config)
          .then((response) => {
            //console.log(response.data);
            this.users = response.data.usuarios;
          })
          .catch((err) => {
            this.logSnackbar(err.response.data);
            console.log(err);
          });
    },

    addCheckout() {
      let config = {
        url: process.env.VUE_APP_APIURL + "createCheckout/",
        method: "post",
        data: {
          username: this.newCheckoutForm.selectedUser,
          comments: this.newCheckoutForm.comments,
          total: this.newCheckoutForm.total,
          payed_with: this.newCheckoutForm.selectedPayment
        },
        withCredentials: true,
        headers: {
          // Esto solo se manda cuando es POST
          "X-CSRFToken": this.$cookies.get("csrftoken"),
          Authorization: "Bearer " + this.$store.state.auth.accessToken,
        },
      };

      axios(config)
          .then((response) => {
            this.logSnackbar(response.data);
            this.getAllCheckouts();
            this.clearNewCheckout();
            //console.log(response.data);
            return;
          })
          .catch((err) => {
            this.logSnackbar(err.response.data);
            console.log(err);
          });
    },
    getAllCheckouts() {
      let config = {
        url: process.env.VUE_APP_APIURL + "getAllCheckouts/",
        method: "get",
        withCredentials: true,
        headers: {
          // Esto solo se manda cuando es POST
          Authorization: "Bearer " + this.$store.state.auth.accessToken,
        },
      };

      axios(config)
          .then((response) => {
            this.itemsCheckout = response.data
            //console.log(response.data);
            return;
          })
          .catch((err) => {
            this.logSnackbar(err.response.data);
            console.log(err);
          });
    },
    clearNewCheckout() {
      this.newCheckoutForm = {
        selectedUser: "",
        comments: "",
        total: null,
        selectedPayment: "Efectivo"
      }
      this.newCheckout = false
    },
  },
  mounted() {
    this.getAllCheckouts()
    this.getAllUsers()
  }
}
</script>

<style scoped>

</style>