<template>
  <v-container fluid style="margin-top: 90px">
    <v-row>
      <v-col cols="12">
        <v-sheet
          elevation="1"
          height="175"
          width="100%"
          class="p-4"
          :style="{
            backgroundImage: `url(${require('@/assets/img/baner-bg.png')})`,
            color: 'white',
          }"
        >
          <h2>
            Bienvenido a tu perfil {{ user.first_name }} {{ user.last_name }}!
          </h2>
          <h4>Actualmente el valor aproximado de tu billetera es:</h4>
          <h3>{{ portfolio_value }} MXN</h3>
        </v-sheet>
      </v-col>
    </v-row>
    <v-container>
      <v-row>
        <v-col cols="12" sm="12" md="8" lg="8">
          <h2>Portafolio de criptos</h2>

          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="6"
              lg="6"
              v-for="(coin, key) in coins"
              :key="key"
            >
              <v-card max-width="344" outlined>
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="text-overline mb-4">{{ coin.name }}</div>
                    <v-list-item-title class="text-h5 mb-1">
                      {{ coin.have }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      >Precio mercado:
                      {{ aproxPortAmount(coin.price) }}
                      MXN</v-list-item-subtitle
                    >
                    <v-list-item-subtitle
                      >Valor aproximado:
                      {{ aproxPortAmount(coin.price * coin.have) }}
                      MXN</v-list-item-subtitle
                    >
                  </v-list-item-content>

                  <v-list-item-avatar tile size="80">
                    <v-img :alt="`${coin.name} crypto moneda`" :src="coin.path">
                    </v-img>
                  </v-list-item-avatar>
                </v-list-item>

                <v-card-actions>
                  <v-dialog
                    transition="dialog-bottom-transition"
                    max-width="600"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" v-bind="attrs" v-on="on"
                        >Solicitar Compra/Venta</v-btn
                      >
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-toolbar color="primary" dark
                          >Notificaión de contacto</v-toolbar
                        >
                        <v-card-text>
                          <div class="text-h6 pa-12">
                            Si das click en aceptar se estara notificando a
                            CriptoMex tu solicitud para comprar o vender tus
                            monedas. Tan pronto podamos nos pondremos en
                            contacto contigo!
                            <br />
                            <br />
                            <v-list-item-subtitle
                              >Mensaje:</v-list-item-subtitle
                            >
                            <div
                              class="p-3 rounded"
                              style="background-color: grey; color: white"
                            >
                              {{
                                `Quiero solicitar compra o venta de mis ${coin.name}`
                              }}!
                            </div>
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                            text
                            @click="createNotification(coin, dialog, `Quiero solicitar compra o venta de mis ${coin.name}`)"
                            class="blue lighten-1"
                            >Aceptar</v-btn
                          >
                          <v-btn
                            text
                            @click="dialog.value = false"
                            class="red lighten-1"
                            >Cancelar</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="4">
          <portfolio
            ref="coins_chart"
            :chart-data="chartData"
            :options="chartOptions"
          ></portfolio>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="8" lg="8">
          <h2>Portafolio de shorts</h2>

          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="6"
              lg="6"
              v-for="(coin, key) in shorts"
              :key="key"
            >
              <v-card max-width="344" outlined>
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="text-overline mb-4">{{ coin.name }}</div>
                    <v-list-item-title class="text-h5 mb-1">
                      {{ coin.have }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      >Precio mercado:
                      {{ aproxPortAmount(coin.price) }}
                      MXN</v-list-item-subtitle
                    >
                    <v-list-item-subtitle
                      >Valor aproximado:
                      {{ aproxPortAmount(coin.price * coin.have) }}
                      MXN</v-list-item-subtitle
                    >
                  </v-list-item-content>

                  <v-list-item-avatar tile size="80">
                    <v-img :alt="`${coin.name} crypto moneda`" :src="coin.path">
                    </v-img>
                  </v-list-item-avatar>
                </v-list-item>

                <v-card-actions>
                  <v-dialog
                    transition="dialog-bottom-transition"
                    max-width="600"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" v-bind="attrs" v-on="on"
                        >Solicitar Compra/Venta</v-btn
                      >
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-toolbar color="primary" dark
                          >Notificaión de contacto</v-toolbar
                        >
                        <v-card-text>
                          <div class="text-h6 pa-12">
                            Si das click en aceptar se estara notificando a
                            CriptoMex tu solicitud para comprar o vender tus
                            monedas. Tan pronto podamos nos pondremos en
                            contacto contigo!
                            <br />
                            <br />
                            <v-list-item-subtitle
                              >Mensaje:</v-list-item-subtitle
                            >
                            <div
                              class="p-3 rounded"
                              style="background-color: grey; color: white"
                            >
                              {{
                                `Quiero solicitar compra o venta de mis (3S) ${coin.name}`
                              }}!
                            </div>
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                            text
                            @click="createNotification(coin, dialog, `Quiero solicitar compra o venta de mis (3S) ${coin.name}`)"
                            class="blue lighten-1"
                            >Aceptar</v-btn
                          >
                          <v-btn
                            text
                            @click="dialog.value = false"
                            class="red lighten-1"
                            >Cancelar</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="4">
          <portfolio
            ref="shorts_chart"
            :chart-data="shortsData"
            :options="chartOptions"
          ></portfolio>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="8" lg="8">
          <h2>Portafolio de longs</h2>

          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="6"
              lg="6"
              v-for="(coin, key) in longs"
              :key="key"
            >
              <v-card max-width="344" outlined>
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="text-overline mb-4">{{ coin.name }}</div>
                    <v-list-item-title class="text-h5 mb-1">
                      {{ coin.have }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      >Precio mercado:
                      {{ aproxPortAmount(coin.price) }}
                      MXN</v-list-item-subtitle
                    >
                    <v-list-item-subtitle
                      >Valor aproximado:
                      {{ aproxPortAmount(coin.price * coin.have) }}
                      MXN</v-list-item-subtitle
                    >
                  </v-list-item-content>

                  <v-list-item-avatar tile size="80">
                    <v-img :alt="`${coin.name} crypto moneda`" :src="coin.path">
                    </v-img>
                  </v-list-item-avatar>
                </v-list-item>

                <v-card-actions>
                  <v-dialog
                    transition="dialog-bottom-transition"
                    max-width="600"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" v-bind="attrs" v-on="on"
                        >Solicitar Compra/Venta</v-btn
                      >
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-toolbar color="primary" dark
                          >Notificaión de contacto</v-toolbar
                        >
                        <v-card-text>
                          <div class="text-h6 pa-12">
                            Si das click en aceptar se estara notificando a
                            CriptoMex tu solicitud para comprar o vender tus
                            monedas. Tan pronto podamos nos pondremos en
                            contacto contigo!
                            <br />
                            <br />
                            <v-list-item-subtitle
                              >Mensaje:</v-list-item-subtitle
                            >
                            <div
                              class="p-3 rounded"
                              style="background-color: grey; color: white"
                            >
                              {{
                                `Quiero solicitar compra o venta de mis (3L) ${coin.name}`
                              }}!
                            </div>
                          </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                            text
                            @click="createNotification(coin, dialog, `Quiero solicitar compra o venta de mis (3L) ${coin.name}`)"
                            class="blue lighten-1"
                            >Aceptar</v-btn
                          >
                          <v-btn
                            text
                            @click="dialog.value = false"
                            class="red lighten-1"
                            >Cancelar</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="4">
          <portfolio
            ref="longs_chart"
            :chart-data="longsData"
            :options="chartOptions"
          ></portfolio>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackbar">
      {{ responseText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  animation: {
    animateRotate: false,
  },
};
import Portfolio from "../components/Portfolio.vue";
import axios from "axios";
export default {
  name: "Dashboard",
  components: { Portfolio },
  data() {
    return {
      snackbar: false,
      responseText: "",
      user: {
        username: "",
        first_name: "",
        last_name: "",
      },
      portfolio_value: "",
      chartOptions,
      chartData: {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: [],
          },
        ],
      },
      shortsData: {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: [],
          },
        ],
      },
      longsData: {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: [],
          },
        ],
      },
      coins: [],
      shorts: [],
      longs: [],
    };
  },
  methods: {
    aproxPortAmount(value) {
      var formatterMxn = new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

      return `${formatterMxn.format(value)}`;
    },
    updateChart() {
      this.$refs.coins_chart.update();
      this.$refs.shorts_chart.update();
      this.$refs.longs_chart.update();
    },
    getRandomColor() {
      var randomColor = require("randomcolor"); // import the script
      return randomColor();
    },
    fetchPorfolio() {
      let config = {
        url: process.env.VUE_APP_APIURL + "fetchSelfPortfolio/",
        method: "post",
        data: {},
        headers: {
          "X-CSRFToken": this.$cookies.get("csrftoken"),
          Authorization: "Bearer " + this.$store.state.auth.accessToken,
        },
      };
      axios(config)
        .then((response) => {
          let aproxPortSum = 0;
          for (let crypto of response.data.portfolio) {
            console.log(crypto);
            this.coins.push({
              name: crypto.crypto.name,
              price: crypto.crypto.priceMxn,
              have: crypto.quantity,
              path: crypto.crypto.imageUrl,
            });

            this.shorts.push({
              name: crypto.crypto.name,
              price: crypto.crypto.price3short,
              have: crypto.short3,
              path: crypto.crypto.imageUrl,
            });

            this.longs.push({
              name: crypto.crypto.name,
              price: crypto.crypto.price3long,
              have: crypto.long3,
              path: crypto.crypto.imageUrl,
            });

            this.chartData.labels.push(crypto.crypto.name);
            this.chartData.datasets[0].backgroundColor.push(
              crypto.crypto.color
            );
            this.chartData.datasets[0].data.push(
              crypto.quantity * crypto.crypto.priceMxn
            );

            aproxPortSum += crypto.quantity * crypto.crypto.priceMxn;

            //Shorts data
            this.shortsData.labels.push(crypto.crypto.name);
            this.shortsData.datasets[0].backgroundColor.push(
              crypto.crypto.color
            );
            this.shortsData.datasets[0].data.push(
              crypto.short3 * crypto.crypto.price3short
            );

            aproxPortSum += crypto.short3 * crypto.crypto.price3short;

            //Longs data
            this.longsData.labels.push(crypto.crypto.name);
            this.longsData.datasets[0].backgroundColor.push(
              crypto.crypto.color
            );
            this.longsData.datasets[0].data.push(
              crypto.long3 * crypto.crypto.price3long
            );

            aproxPortSum += crypto.long3 * crypto.crypto.price3long;
          }
          this.portfolio_value = this.aproxPortAmount(aproxPortSum);
          this.user = response.data.user_data;
          this.updateChart();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    isLogged() {
      return this.$store.state.auth.isValid;
    },
    logSnackbar(msg) {
      this.responseText = msg.message;
      this.snackbar = true;
    },
    createNotification(coin, dialog, message) {
      let config = {
        url: process.env.VUE_APP_APIURL + "createNotification/",
        method: "post",
        data: {
          message: message,
          coin_id: coin.id,
        },
        headers: {
          "X-CSRFToken": this.$cookies.get("csrftoken"),
          Authorization: "Bearer " + this.$store.state.auth.accessToken,
        },
      };

      axios(config)
        .then((response) => {
          this.logSnackbar(response.data);
          dialog.value = false;
        })
        .catch((err) => {
          this.logSnackbar(err.response.data);
          console.log(err.response.data);
          dialog.value = false;
        });
    },
  },
  mounted() {
    this.fetchPorfolio();
  },
};
</script>