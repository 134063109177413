<template>
  <button class="btn" type="button" id="checkout-button" @click="doStripeCheckout">Proceder a págo</button>
</template>

<script>
import axios from "axios"

export default {
  name: "StripeCheckout",
  props: {
    beforeCheckout: {
      required: false,
      type: Function
    },
    clientUsername: {
      required: true,
      type: String
    },
    orderNumber: {
      required: true,
      type: Number
    }
  },
  data() {
    return {
      stripeApiKey: process.env.VUE_APP_STRIPE_API_KEY,
      items: []
    }
  },
  mounted() {
    /**
     * Mounting stripe in Vue component
     */
    let stripeJs = document.createElement('script')
    stripeJs.setAttribute('src', "https://js.stripe.com/v3/")
    document.head.appendChild(stripeJs)
  },
  methods: {
    /**
     * retrieve a checkout session and redirect to stripe checkout page
     */
    doStripeCheckout() {
      let stripe = window.Stripe(this.stripeApiKey)
      this.$store
          .dispatch("checkout/doStripeCheckout", {
            stripe: stripe,
            client_username: this.clientUsername,
            client_order_id: this.orderNumber
          })
    },
  }
}
</script>

<style scoped>

</style>