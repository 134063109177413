import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import Dashboard from "../views/Dashboard.vue";
import NotFound from "../views/NotFound.vue";
import MainLayout from "../views/layouts/MainLayout.vue";
import store from "../store";
import Forgot from "../views/Forgot.vue";
import DashboardAdmin from "../views/DashboardAdmin.vue";
import OrderAdmin from "../views/OrderAdmin.vue";
import Payment from "../views/Payment";
import Rejected from "../views/Rejected";
import Thanks from "../views/Thanks";
import CryptoAdmin from "../views/CryptoAdmin"

Vue.use(VueRouter);

let mainPages = {
  path: "/",
  component: MainLayout,
  name: "HomeLayout",
  children: [
    {
      path: "/",
      name: "Home",
      component: Home,
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/payment",
      name: "Payment",
      component: Payment,
    },
    {
      path: "/thanks",
      name: "Thanks",
      component: Thanks,
    },
    {
      path: "/rejected",
      name: "Rejected",
      component: Rejected,
    },
  ],
};

let dashboardPages = {
  path: "/dashboard",
  component: MainLayout,
  name: "DashboardLayout",
  beforeEnter: (to, from, next) => {
    store
      .dispatch("auth/refreshToken")
      .then(() => {
        if (store.state.auth.accessToken && store.state.auth.isTokenValid) {
          next();
        } else {
          next({
            name: "Login",
          });
        }
      })
      .catch(() => {
        next({
          name: "Login",
        });
      });
  },
  children: [
    {
      path: "/dashboard",
      name: "Dashboard",
      components: {
        default: Dashboard,
      },
    },
  ],
};

let adminPages = {
  path: "/dashboard_admin",
  component: MainLayout,
  name: "AdminDashboardLayout",
  beforeEnter: (to, from, next) => {
    store
      .dispatch("auth/refreshToken")
      .then(() => {
        if (
          store.state.auth.accessToken &&
          store.state.auth.isTokenValid &&
          store.state.auth.isAdmin
        ) {
          next();
        } else {
          next({
            name: "Login",
          });
        }
      })
      .catch(() => {
        next({
          name: "Login",
        });
      });
  },
  children: [
    {
      path: "/dashboard_admin",
      name: "DashboardAdmin",
      components: {
        default: DashboardAdmin,
      },
    },
    {
      path: "/order_admin",
      name: "OrderAdmin",
      components: {
        default: OrderAdmin,
      },
    },
    {
      path: "/crypto_admin",
      name: "CryptoAdmin",
      components: {
        default: CryptoAdmin,
      },
    },
  ],
};

const routes = [
  mainPages,
  dashboardPages,
  adminPages,
  {
    path: "/:pathMatch(.*)*",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
